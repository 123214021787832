import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'advice',
    loadChildren: () => import('./advice/advice.module').then( m => m.AdvicePageModule)
  },
  {
    path: 'information',
    loadChildren: () => import('./information/information.module').then( m => m.InformationPageModule)
  },
  {
    path: 'advice1/:id',
    loadChildren: () => import('./advice1/advice1.module').then( m => m.Advice1PageModule)
  },
  {
    path: 'advice2',
    loadChildren: () => import('./advice2/advice2.module').then( m => m.Advice2PageModule)
  },
  {
    path: 'advice3',
    loadChildren: () => import('./advice3/advice3.module').then( m => m.Advice3PageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
